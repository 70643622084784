import { Link } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { GetInTouch } from '../components/GetInTouch';
import { StaticImage } from 'gatsby-plugin-image';
import { SpecialPanel } from '../components/Special-Panel';
import { colors, device } from '../components/layout/GlobalStyles';
import styled from '@emotion/styled';
import { TampaPanel } from '../components/Tampa-Panel';
import { FAQ } from '../components/Faq';
import { CallSection } from '../components/CallSection';
import { Seo } from '../components/Seo';

const specials = [
    {
        type: 'New Patient Special',
        price: '$119.99',
        subButton: 'Call now to schedule',
        benefits: ['Comprehensive Exam', 'Fluoride Treatment', 'Teeth Cleaning', 'Digital X-rays'],
    },
];

const questions = [
    {
        question: 'Will There Be Any Additional Costs At My First Visit?',
        answer: 'All eligible new patients who take advantage of our special will receive digital x-rays, a comprehensive cleaning, a fluoride treatment, and an oral health exam. If Dr. Kasey discovers any issues or concerns during your oral assessment, she will work with you to develop and recommend a treatment plan that works best for you. If no other procedures are performed during your visit, no additional costs will be added.',
    },
    {
        question: 'What Happens After My First Visit?',
        answer: 'After you have taken advantage of our new patient special, one of our team members will be happy to help schedule your next appointment and layout all possible financing options. In addition, you can receive more savings and discounts on great dental care when you become a member!',
    },
    {
        question: 'Can I Use Dental Insurance?',
        answer: 'Of course! If we are in-network with your insurance provider, you are always welcome to use your benefits, but you will not be able to take advantage of our $119.99 new patient special. Most dental insurance plans cover preventive care, but we offer a free insurance benefits check to ensure that you get the same great dental care for the lowest price possible.',
    },
];

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin-bottom: 2em;
    h2 {
        margin: 0.5em 0em;
    }
    p {
        color: #0e3347;
        font-weight: 400;
        margin: 1em 0em 1.5em;
    }
    .text-container {
        margin-bottom: 2em;
    }
    @media ${device.tablet} {
        margin: 4em 0em;
        flex-direction: row;
        justify-content: space-around;
        .text-container {
            width: 550px;
        }
    }
    @media ${device.laptopL} {
        margin: 2em auto;
        max-width: 1210px;
        .text-container {
            margin-bottom: 2em;
        }
    }
`;

const NewPatient: Page = () => (
    <>
        <DefaultLayout>
            <Seo
                title="New Patient Special, FL | Smilecraft Dental Studio"
                description="New Patient Special Are you new to the area? Visit Smilecraft Dental Studio to receive your initial dental exam, cleaning, and X-rays. "
            />
            <RegularHero prevPage="home" currentPage="New Patient Special">
                <StaticImage
                    quality={100}
                    className="regular-hero-image"
                    src="../images/dentist-instructing.jpg"
                    placeholder="blurred"
                    alt="dentist demonstrating with patient"
                />
            </RegularHero>
            <StyledSection>
                <div className="text-container">
                    <h2>Looking For A Dentist In Lutz?</h2>
                    <p>
                        We want our SmileCraft family to grow even bigger. If you’re a qualifying
                        new patient, be sure to take advantage of our special and receive a
                        comprehensive exam, radiographs, teeth cleaning, and fluoride treatment.
                    </p>
                    <p style={{ fontSize: '14px' }}>
                        *New patients only. Not valid in cases of periodontal disease. Cannot be
                        used with insurance. Additional fees may apply for related services, which
                        may be required in individual cases.
                    </p>
                </div>
                <SpecialPanel specials={specials} />
            </StyledSection>
            <TampaPanel />
            <FAQ questions={questions} />
            <CallSection />
        </DefaultLayout>
    </>
);

export default NewPatient;
