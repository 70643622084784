import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/mask-slant.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';

const StyledTampaPanel = styled.div`
    .box {
        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.2);
        border-radius: 16px;
        padding: 2em 1em;
        margin: 1em;
    }
    h3 {
        text-align: center;
    }
    h4 {
        margin: 0.5em 0em 0.25em;
    }
    .header {
        font-size: 35px;
        font-weight: 900;
    }
    @media ${device.tablet} {
        h4 {
            font-size: 32px;
        }
    }
    @media ${device.laptop} {
        .header {
            font-size: 36px;
            margin-bottom: 1.5em;
        }
        .box {
            background: #efefef;
            border-radius: 16px;
            padding: 2em;
            margin: 1em;
            max-width: 414px;
            min-height: 450px;
        }
        .box-container {
            max-width: 1306px;
            margin: 0 auto;
            display: flex;
        }
    }
    @media ${device.desktop} {
        .header {
            font-size: 42px;
        }
    }
`;

export const TampaPanel: React.FC = () => {
    return (
        <StyledTampaPanel>
            <h3 className="header">$119.99 New Patient Special Includes</h3>
            <div className="box-container">
                <div className="box">
                    <StaticImage
                        src="../images/svgs/tooth.svg"
                        alt="tooth symbol"
                        placeholder="none"
                    />
                    <h4>Teeth Cleaning</h4>
                    <p>
                        Essential for maintaining superb oral health, a professional teeth cleaning
                        is recommended once every six months. We also provide a fluoride treatment
                        that promotes healthy tooth enamel and helps fight against cavities.
                    </p>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/microscope.svg"
                        alt="microscrop symbol"
                        placeholder="none"
                    />
                    <h4>Comprehensive Exam</h4>
                    <p>
                        Dr. Kasey will sit down with you and explain any problems or issues that may
                        have been discovered. She will answer any questions or concerns you might
                        have regarding your oral health or future treatments.
                    </p>
                </div>
                <div className="box">
                    <StaticImage
                        src="../images/svgs/tooth-two.svg"
                        alt="microscrop symbol"
                        placeholder="none"
                    />
                    <h4>Digital X-Rays</h4>
                    <p>
                        At our office we use digital x-rays for diagnoses. This method allows for
                        quicker and more comfortable visits and helps keep you healthier in the long
                        run with less exposure to radiation.
                    </p>
                </div>
            </div>
        </StyledTampaPanel>
    );
};
