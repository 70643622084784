import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';
import mask from '../images/svgs/hero-mask.svg';

const StyledCallSection = styled.div`
    justify-content: center;
    form {
        padding: 4em;
        background: ${colors.white};
        /* Card-drop-shadow */

        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.1);
        border-radius: 16px;
    }
    .text-container {
        padding: 1em 0.75em;
    }
    .icons {
        display: none;
        width: 700px;
    }
    h4 {
        margin-bottom: 0.5em;
        width: 75%;
    }
    form {
        margin-top: 2em;
        .item {
            display: flex;
            flex-direction: column;
        }
        label {
            font-size: 14px;
        }
        button {
            margin-top: 1em;
            width: 100%;
        }
        .buttons {
            display: flex;
            padding: 0em 0em;
            flex-direction: column;
            justify-content: center;
        }
        input {
            text-indent: 12px;
            margin: 1em 0em;
            padding: 1em 0.25em;
            background: #f9f9f9;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            border-radius: 5px;
        }
    }
    @media ${device.tablet} {
        form {
            .buttons {
                flex-direction: row;
                padding: 0em 4em;
                button {
                    width: 45%;
                }
            }
        }
        .icons {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-top: 2em;
            width: 800px;
        }
        .icons-item {
            padding: 0em 0em 0em 5em;
            p {
                margin-top: 0.5em;
                padding-right: 8em;
            }
            h6 {
                padding-right: 8em;
                font-size: 32px;
                margin: 0em;
                font-weight: 900;
            }
            display: flex;
            flex-direction: column;
            align-items: start;
            margin: 0.75em 0em;
        }
        padding: 4em;
        h4 {
            line-height: 65px;
            font-size: 50px;
        }
    }
    @media ${device.laptop} {
        display: flex;
        padding: 0em;
        .buttons {
        }
        .text-container {
            padding-right: 4em;
            min-width: 40%;
        }
    }
`;

export const CallSection: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledCallSection>
            <div className="icons">
                <div className="icons-item">
                    <h6>FUN, HIGH-SPIRITED ATMOSPHERE</h6>
                    <p>
                        Experience a dental visit unlike any other. Our up-beat team will ensure you
                        receive high-quality dental care in a welcoming, judgment-free environment.
                    </p>
                </div>
                <div className="icons-item">
                    <h6>UNBELIEVABLE OFFICE COMFORTS</h6>
                    <p>
                        Whether you’re immersed in a virtual tropical beach listening to the waves
                        crash or you’re using one of our amazing eye massagers, you’ll be calm and
                        comfortable as we improve your smile.
                    </p>
                </div>
                <div className="icons-item">
                    <h6>A PASSIONATE, CARING DENTIST</h6>
                    <p>
                        Always striving to stay on the leading edge of dentistry, Dr. Kasey sharpens
                        her dental skill-set by discovering new and exciting tools and technology
                        that make dental visits easier and less painful for her patients.
                    </p>
                </div>
            </div>
            <div className="text-container">
                <form onSubmit={onSubmit}>
                    <h4>Send Us Your Info</h4>
                    <div className="item">
                        <label htmlFor="fullName">Full Name*</label>
                        <input
                            placeholder="Full Name *"
                            type="text"
                            id="fullName"
                            name="fullName"
                        />
                    </div>
                    <div className="item">
                        <label htmlFor="name">Email address *</label>
                        <input
                            placeholder="example@gmail.com"
                            type="text"
                            id="email"
                            name="email"
                        />
                    </div>
                    <div className="item">
                        <label htmlFor="phone">Tel*</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            required
                        />
                    </div>
                    <div className="buttons">
                        {submitState === 'submitted' ? (
                            <>
                                <Button
                                    disabled
                                    color="blue"
                                    style={{ cursor: 'auto', color: `${colors.white}` }}
                                >
                                    Thank you!
                                </Button>
                            </>
                        ) : (
                            <Button color="blue" type="submit">
                                {submitState === 'submitting' ? 'Sending message...' : 'Submit'}
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </StyledCallSection>
    );
};
